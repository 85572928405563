<template>
  <div class="pagePadding">
    <div class="topDiv">
      <Form inline class="formMarginBtm20">
        <FormItem>
          <span>通知单号：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight60" v-model="queryFrom.order_number"></i-input>
        </FormItem>
        <FormItem>
          <span>供应商名称：</span
          ><Select class="iviewIptWidth250 marginRight60" v-model="queryFrom.supplier_id" clearable filterable>
            <Option v-for="item in supplierNameList" :value="item.supplier_id" :key="item.supplier_id">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>下单时间：</span>
          <DatePicker  :editable="true" class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker><span class="choseTimeZhi">至</span><DatePicker :editable="true" :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
        </FormItem>
        <br />
        <FormItem>
          <span>状态：</span
          ><Select class="iviewIptWidth250" v-model="queryFrom.purchase_return_state" clearable>
            <Option v-for="item in orderStateList" :value="item.value" :key="item.value + item.name">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="addReturn">新增</span>
        </FormItem>
      </Form>
    </div>
    <div class="tabelDiv">
      <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" totalText="条记录" :loading="isLoad">
        <div slot="summary" class="summary" v-if="listData.length > 0">
          <div>
            <span style="margin-right: 20px;">总数量：{{ totalNum }}</span>
            <span>合计金额：</span>
            <span class="color389">¥{{ totalMoney }}</span>
          </div>
        </div>
      </Table>
    </div>

    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
    <!--     删除-->
    <tips-component showModal="delete" v-if="tipStatus" @cancleBtn="tipStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--     反冲-->
    <tips-component showModal="recoil" v-if="recoilStatus" @cancleBtn="recoilStatus = false" @sureBrn="sureRecoil"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name:'purchaseReturnsConsign',
  components: {
    Table,
    TipsComponent,
  },
  computed: {
    ...mapState(['supplierNameList']),
  },
  data() {
    return {
      paging: null,
      clickId: null,
      status: false,
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
        { typeName: '调拨单', typeID: 13 },
      ],
      template_id: '',
      template_type: 1,
      typeList: [],
      recoilStatus: false,
      tipStatus: false,
      queryFrom: {
        page: 1,
        rows: 10,
        order_number: '',
        supplier_id: '',
        start_time: '',
        end_time: '',
        purchase_return_state: '',
      },
      options: {},
      totalNum: 0,
      totalMoney: 0,
      orderStateList: [
        {
          name: '草稿',
          value: '1',
        },
        {
          name: '待发货',
          value: '2',
        },
        {
          name: '待反冲',
          value: '4',
        },
        {
          name: '已完成',
          value: '3',
        },
      ],
      isLoad: true,
      supplierList: [],
      orderTypeList: [],
      orderStatusList: [],
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '通知单号',
          align: 'center',
          sortable: 'custom',
          key: 'order_number',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                param.row.order_number
              ),
            ])
          },
          width: 150,
        },
        {
          title: '供应商名称',
          align: 'center',
          key: 'supplier_name',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class:'TextAlign_lf',
                },
                param.row.supplier_name
              ),
            ])
          },
        },
        {
          title: '退货金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', param.row.total_amount ? '¥' + param.row.total_amount : '0')])
          },
          width: 116,
        },
        {
          title: '产品',
          align: 'center',
          key: 'product_list',
          minWidth: 140,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class:'TextAlign_lf',
                },
                param.row.product_list
              ),
            ])
          },
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          width: 70,
        },
        {
          title: '下单时间',
          key: 'creatTime',
          sortable: 'custom',
          align: 'center',
          width: 150,
        },
        {
          title: '备注',
          key: 'note',
          align: 'center',
          minWidth: 130,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.note
              ),
            ])
          },
        },
        {
          title: '状态',
          key: 'purchase_return_state_str',
          align: 'center',
          width: 80,
        },

        {
          title: '操作',
          align: 'center',
          width: 180,
          render: (h, param) => {
            let status = param.row.purchase_return_state
            if (status == '1') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.editRow(param.row)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      marginLeft: '40px',
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            } else if (status == '2') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                      marginLeft: '20px',
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '3' || status == '9') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.$Message.warning('功能开发中...')
                      },
                    },
                  },
                  '导出清单'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else if (status == '4') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.recoil(param.row)
                      },
                    },
                  },
                  '反冲'
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.clickId = param.row.id
                        this.status = true
                        this.getTemplate()
                      },
                    },
                  },
                  '打印'
                ),
              ])
            }
          },
        },
      ],
      listData: [],
      total: 1,
      itemInfo: {},
    }
  },
  activated () {
    if(sessionStorage.getItem('updataCache') == 0) {
      this.queryList()
      sessionStorage.setItem('updataCache','1')
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$apiConsign.templateInfo, { template_type: this.template_type}, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$apiConsign.recentTemplate,{template_type: this.template_type},true).then((res) => {
        if(res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$apiConsign.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 打印模板
    surePrint() {
      let api_info = `${this.$apiConsign.pruchaseReturnDetail.url}?id=${this.clickId}`
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$apiConsign.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '采购退货通知单.xlsx'
        aLink.click()
      })
    },
    // 列表单号、时间排序
    sortChange(data) {
      console.log(data)
      if (data.key === 'creatTime') {
        this.queryFrom.sort_str = 'create_time'
      } else {
        this.queryFrom.sort_str = data.key
      }
      this.queryFrom.sort_type = data.order
      this.queryList()
    },
    sureRecoil() {
      this.$http.get(this.$apiConsign.pruchaseReturnRecoil, { id: this.itemInfo.id }, true).then(res => {
        this.$Message.success('反冲成功')
        this.recoilStatus = false
        this.query()
      })
    },
    // 点击反冲按钮
    recoil(row) {
      this.itemInfo = row
      this.recoilStatus = true
    },
    // 编辑
    editRow(row) {
      this.$router.push({
        path: '/editPurchaseReturnConsign',
        query: {
          id: row.id,
        },
      })
    },
    // 查看详情
    goDetails(row) {
      this.$router.push({
        path: '/purchaseReturnsDetailsConsign',
        query: {
          id: row.id,
        },
      })
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$apiConsign.pruchaseReturnManage, { id: this.itemInfo.id }, true).then(res => {
        this.query()
        this.tipStatus = false
      })
    },
    // 点击删除
    deleteRow(item) {
      this.itemInfo = item
      this.tipStatus = true
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      this.$http.get(this.$apiConsign.pruchaseReturnManage, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_amount
        this.totalNum = res.total_number
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.start_time = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.start_time) - 86400000
          },
        }
      } else {
        this.queryFrom.end_time = e
      }
    },
    addReturn() {
      this.$router.push({ path: '/addPurchaseReturnsConsign' })
    },
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
  },
}
</script>

<style scoped lang="less">
.item{
  margin-bottom: 20px;
}
.marginRight60 {
  margin-right: 60px;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;

    .form {
      height: 36px;
      .label {
        font-size: 14px;
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
</style>
